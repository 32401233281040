import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css'; 
import Loader from './components/Control/Loader';

const Login = lazy(() => import('./pages/Auth/Login'));
const Dashboard = lazy(() => import('./pages/Home/Dashboard'));
const About = lazy(() => import('./pages/Other/About'));
const Terms = lazy(() => import('./pages/Other/Terms'));
const Privacy = lazy(() => import('./pages/Other/Privacy'));
const Language = lazy(() => import('./pages/Other/Language'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader loading="true" />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/orders" element={<Dashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/language" element={<Language />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
export default App;